@use "sass:meta" as ---vjv2uzyrvi;@mixin icon-styles {
  :global([class*="icon"]) {
    @content;
  }
}

@mixin active {
  &:global(#{$active}) {
    @content;
  }
}

@mixin accent {
  &:global(#{$accent}) {
    @content;
  }
}

@mixin completed {
  &:global(#{$completed}) {
    @content;
  }
}


@mixin accent-styles {
  :global(.is-accent),
  :global(.strong),
  strong {
    @content
  }
}

@mixin nestedAccent {
  :global(#{$accent}) {
    @content;
  }
}

@mixin disabled {
  &:global(#{$disabled}),
  &[disabled] {
    @content;
  }
}

@mixin invalid {
  &:global(#{$invalid}),
  &[aria-invalid] {
    @content;
  }
}


@mixin with-loader {
  position: relative;

  :global(.loader) {
    @content;
  }
}

@mixin with-mobile-cta-button {
  :global(.with-mobile-cta-button) & {
    @content
  }
}

@mixin with-cta-banner {
  :global(.with-cta-banner) & {
    @content
  }
}

@mixin with-promo-banner {
  :global(.with-promo-banner) & {
    @content
  }
}

@mixin with-promo-banner--tall {
  :global(.with-promo-banner--tall) & {
    @content
  }
}

@mixin isAnimated {
  :global(.is-animated) & {
    @content
  }
}

;@include ---vjv2uzyrvi.load-css("sass-embedded-legacy-load-done:86387");